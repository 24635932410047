<template>
  <div class="main">
    <div class="container">
      <div class="search-area">
        <div class="search">
          <div class="search__label">搜索</div>
          <el-input
            placeholder="输入手机号"
            prefix-icon="el-icon-search"
            size="medium"
            v-model="phone"
            @blur="handleSearch"
            @keyup.enter.native="handleSearch"
          >
          </el-input>
          <el-button type="primary" size="medium" round @click="handleSearch">搜索</el-button>
        </div>
        <div class="status">
          <div class="status__label">短信状态：</div>
          <el-switch
            size="big"
            disabled
            v-model="status"
          >
          </el-switch>
          <div class="status--active" v-if="status === 1">已开启</div>
          <div class="status--default" v-else>已关闭</div>
        </div>
      </div>
      <div class="table-area">
        <div class="table__head">
          <div class="total-area">
            <div class="total__label">发送记录</div>
            <div class="total">（已发送<span>{{send_num}}条</span>，剩余<span>{{current_num}}条</span>）</div>
          </div>
        </div>
        <el-table
          :data="tableData"
          border
          style="width: 100%">
          <el-table-column
            prop="id"
            label="ID"
            min-width="100">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            min-width="120">
          </el-table-column>
          <el-table-column
            prop="name"
            label="用户名"
            min-width="120">
          </el-table-column>
          <el-table-column
            prop="message"
            label="发送内容"
            min-width="400">
          </el-table-column>
          <el-table-column
            prop="created_at"
            label="发送日期"
            min-width="150">
          </el-table-column>
          <el-table-column
            label="发送状态"
            min-width="120">
            <template slot-scope="scope">
              {{ scope.row.status > 0 ? "发送成功" : "发送失败" }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="table-pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :hide-on-single-page="total < 15"
          :page-sizes="[15]"
          :page-size="15"
          background
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lastKeyword: '',
      phone: '',
      current_num: 0, // 当前剩余条数
      send_num: 0, // 已发送条数
      status: 0, // 状态：1 开通，0 关闭
      tableData: [],
      total: 0,
      currentPage: 1, // 当前页
    }
  },
  methods: {
    /**
     * 搜索
     */
    handleSearch() {
      if (this.phone != this.lastKeyword) {
        this.lastKeyword = this.phone;
        this.currentPage = 1;
        this.getSendlogList();
      }
    },
    /**
     * 获取发送记录
     */
    getSendlogRecord() {
      this.$axios({
        href: "/api/app/meiye/sms/info",
      }).then((res) => {
        console.log('getSendlogRecordRes', res);
        if (res.data) {
          this.current_num = res.data.current_num;
          this.send_num = res.data.send_num;
          this.status = res.data.status;
        }
      }).catch((err) => {
        console.log('getSendlogRecordErr', err)
      });
    },
    /**
     * 获取短信列表
     */
    getSendlogList() {
      this.$axios({
        href: "/api/app/meiye/sms/sendlog",
        data: {
          phone: this.phone,
          page: this.currentPage,
        }
      }).then((res) => {
        console.log('getSendlogListRes', res);
        this.tableData = res.data.items;
        this.total = res.data.total;
        this.currentPage = res.data.currentPage;
      }).catch((err) => {
        console.log('getSendlogListErr', err)
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getSendlogList();
    },
  },
  created() {
    this.getSendlogRecord();
    this.getSendlogList();
  }
}
</script>

<style lang="scss" scoped>
  .main {
    width: 100%;
    padding: 20px 30px;
    box-sizing: border-box;
    .container {
      width: 100%;
      padding-bottom: 30px;
      background-color: #FFFFFF;
      .search-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 20px 30px;
        border-bottom: 1px solid #F3F3F3;
        box-sizing: border-box;
        .search {
          display: flex;
          align-items: center;
          &__label {
            width: 50px;
            font-size: 16px;
            font-weight: bold;
            color: #000000;
          }
          /deep/ .el-input__inner {
            width: 284px;
            border-radius: 20px;
          }
        }
        .status {
          display: flex;
          align-items: center;
          &__label {
            margin-right: 7px;
            font-size: 16px;
            color: #3E4E5F;
          }
          &--active {
            margin-left: 14px;
            font-size: 16px;
            color: #409EFF;
          }
          &--default {
            margin-left: 14px;
            font-size: 16px;
            color: #999999;
          }
        }
      }
      .table-area {
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;
        .table__head {
          display: flex;
          align-items: center;
          height: 72px;
          .total-area {
            display: flex;
            align-items: baseline;
            .total__label {
              font-size: 16px;
              color: #3E4E5F;
            }
            .total {
              font-size: 13px;
              color: #999999;
              span {
                color: #000000;
              }
            }
          }
        }
        .el-pagination {
          text-align: right;
          padding: 20px 0 0 0;
        }
      }
    }
  }
</style>